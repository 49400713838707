import React, { useState, useEffect } from "react"
import { Button, Form, Card } from 'react-bootstrap'
import Input from '../components/Input'
import api from '../utils/API'
import { navTo } from "../utils/navigation"
import Header from '../components/header'
import Spinner from '../components/spinner'
import Layout from '../components/layout'

const VerifyTotp = () => {


  const [totp, setTotp] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)


  const onChange = ({ target: { name, value } = {} }) => {
    setTotp(value)
  }

  const handleError = (err) => {
    setIsLoading(false)
    setSuccess(null)
    const { response } = err
    if (response && response.status === 403) {
      setError("Session Expired")
      setTimeout(() => navTo.root(), 1000)
    } else {
      if (response && response.data) {
        setError(response.data.error)
      } else {
        setError("Unexpected Error, please contact our friendly support person")
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setIsLoading(true)
      api.verifyTotp(totp)
        .then(() => {
          setSuccess("One time password verified")
          navTo.consents()
        })
        .catch((err) => {
          handleError(err)
        })
    }
  }

  const resendToken = () => {
    setError(null)
    setIsLoading(true)
    api.resendToken()
      .then(() => {
        setIsLoading(false)
        setSuccess("One time password sent")
      })
      .catch((err) => {
        handleError(err)
      })

  }

  useEffect(() => {
    setIsLoading(true)
    api.getStatus()
      .then(() => setIsLoading(false))
      .catch(err => navTo.root())
  }, [])


  return (
    <Layout>
      <div className="container py-4">
        <Card>
          <Card.Body>
            <Card.Title>Verify</Card.Title>
            <div className="py-4">
              We just sent a one time password to your mobile number. Please enter it here to proceed.
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">One Time Password</label>
                <div className="col-sm-10">
                  <Input
                    name='oneTimePassword'
                    placeholder='Enter one time password'
                    onChange={onChange}
                    validateErrorMessage='One time password cannot be empty'
                    required />
                </div>
              </div>

              {
                (success == null && error) &&
                <div className="alert alert-danger py-2" >{error}</div>
              }
              {
                success &&
                <div className="alert alert-success py-2" >{success}</div>
              }
              <Button variant="dark" className="btn-block" type="submit">Verify</Button>
              <Button variant="dark" className="btn-block" onClick={resendToken}>Re-Send</Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Spinner isLoading={isLoading} />
    </Layout>
  )
}

export default VerifyTotp
